<template>
  <div class="service-providers-wrapper">
    <PageBanner
      background="alternative"
      heading="Tjänster och leverantörer"
      subheading="Öppen Fiber är ett öppet, operatörsneutralt fibernät. Det innebär att flera leverantörer av tjänster som bredband, tv och telefoni är anslutna till- och konkurrerar på lika villkor i nätet. För dig som kund skapar detta fri konkurrens och förutsättningar att få ett brett utbud av prisvärda internet-, telefoni-, och TV-tjänster att välja bland." />
    <div class="container service-providers">
      <LoadingSpinner v-if="loading" />

      <div
        v-else
        class="row">
        <div class="col-lg-8 order-1 order-lg-0">
          <template v-for="(operator, index) in content.items">
            <ServiceProviderCard
              :id="'service_provider_' + index"
              :key="index"
              :title="operator.fields.title"
              :description="operator.fields.description"
              :image-src="operator.fields.logo.fields.file.url"
              :services-provided="operator.fields.servicesProvided"
              :website-url="operator.fields.website"
              class="service-providers-card" />
          </template>
        </div>

        <div class="col-lg-4 order-0 order-lg-1">
          <SideCard
            title="Se vilka tjänster du kan beställa"
            description="Sök på din adress för att se om du är ansluten och vilka leverantörer och tjänster du kan välja mellan.">
            <template v-slot:button>
              <MainButton
                label="Se vilka tjänster du kan beställa"
                :expanded="true"
                type="secondary"
                @click="goToAddressSearch" />
            </template>
          </SideCard>

          <div class="service-providers-quicklist">
            <h3 class="service-providers-quicklist__title">
              Alla tjänsteleverantörer
            </h3>
            <p class="service-providers-quicklist__description d-none d-lg-block">
              Här listas alla tjänsteleverantörer av Öppen Fiber
            </p>

            <ul class="service-providers-quicklist__list d-none d-lg-block">
              <li
                v-for="(operator, index) in content.items"
                :key="index">
                <a :href="'#service_provider_' + index">{{ operator.fields.title }}</a>
              </li>
            </ul>

            <div class="d-block d-lg-none">
              <Dropdown
                id="service_providers_quicklist_dropdown"
                :options="serviceProviders"
                @onChange="jumpToServiceProvider($event)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions, mapState
} from 'vuex'

import LoadingSpinner from '@/components/LoadingSpinner'
import PageBanner from '@/components/views/PageBanner'
import MainButton from '@/components/MainButton'
import SideCard from '@/components/SideCard'
import ServiceProviderCard from '@/components/ServiceProviderCard'
import Dropdown from '@/components/form-components/Dropdown'

import {
  RoutePath, RouteName
} from '@/router/router-constants'

const SERVICE_PROVIDER_DROPDOWN_MAPPER = (item, index) => {
  return {
    label: item.fields.title,
    value: index
  }
}

export default {
  components: {
    Dropdown,
    SideCard,
    ServiceProviderCard,
    MainButton,
    LoadingSpinner,
    PageBanner
  },
  computed: {
    ...mapState('ServiceProviders', [
      'loading',
      'exception',
      'content'
    ]),
    serviceProviders: function () {
      if (!this.content) {
        return []
      }

      return this.content.items.map(SERVICE_PROVIDER_DROPDOWN_MAPPER)
    }
  },
  mounted () {
    this.loadContent()
  },
  methods: {
    ...mapActions('ServiceProviders', ['loadContent']),
    goToAddressSearch () {
      this.$router.push({ name: RouteName.public.AddressSearch })
    },
    jumpToServiceProvider (item) {
      this.$router.push({ path: `${RoutePath.public.ServiceProviders}#service_provider_${item}` })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.service-providers {
  margin-top: 50px;

  &-card:nth-last-child(2n) {
    background-color: $grey-100;
  }

  &-quicklist {
    margin: 40px 0;
    padding: 32px;
    border: 1px solid $grey-200;

    &__title {
      font-size: 22px;
    }

    &__description {
      margin: 25px 0;
    }

    &__list {
      list-style-type: none;
      padding: 0;

      li {
        margin: 12px 0;
      }
    }
  }
}
</style>
