<template>
  <div class="provider">
    <div class="provider-top">
      <logo-container
        :logo-url="imageSrc"
        :title="title" />

      <div class="provider-top-services-provided">
        <img
          v-if="servicesProvided.includes('TV')"
          alt="Tv"
          src="@/assets/svg/service-provider/tv.svg"
          class="provider-top-service-provided provider-top-service-provided--tv"
          height="55"
          width="55">
        <img
          v-if="servicesProvided.includes('Telecommunications')"
          alt="Telecommunications"
          src="@/assets/svg/service-provider/telecommunications.svg"
          class="provider-top-service-provided provider-top-service-provided--telecommunications"
          height="55"
          width="55">
        <img
          v-if="servicesProvided.includes('Broadband')"
          alt="Broadband"
          src="@/assets/svg/service-provider/broadband.svg"
          class="provider-top-service-provided provider-top-service-provided--broadband"
          height="55"
          width="55">
      </div>
    </div>

    <div class="provider-description">
      <RichTextRenderer
        :document="description" />
    </div>

    <MainButton
      label="Gå till leverantörens hemsida"
      @click="goTo(websiteUrl)" />
  </div>
</template>

<script>
import MainButton from '@/components/MainButton'
import LogoContainer from '@/components/common/LogoContainer'
import RichTextRenderer from 'contentful-rich-text-vue-renderer'

export default {
  name: 'ServiceProviderCard',
  components: {
    MainButton,
    RichTextRenderer,
    LogoContainer
  },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: Object,
      required: true
    },
    imageSrc: {
      type: String,
      required: true
    },
    servicesProvided: {
      type: Array,
      required: true
    },
    websiteUrl: {
      type: String,
      required: true
    }
  },
  methods: {
    goTo (link) {
      window.location.href = link
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.provider {
  padding: 32px;
  margin-bottom: 32px;

  &-top {
    display: flex;
    justify-content: space-between;

    &-services-provided {
      display: flex;
      align-items: center;
    }

    &-service-provided {
      margin-left: 30px;

      @media(max-width: $breakpoint-md) {
        width: 30px;
        height: 30px;
      }
    }
  }

  &-description {
    margin-top: 35px;
    margin-bottom: 35px;
  }
}
</style>
